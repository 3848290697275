import AWS from "aws-sdk";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { Grid, Typography, Button } from "@mui/material";

import { useAlert, useAxios } from "../../../hooks";
import pages from "../../../constants/pages";

const useStyles = makeStyles(() => ({
  buttonStyle: {
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    color: "#fff",
    padding: "5px 35px",
    fontSize: "16px",
    background: "linear-gradient(180deg, #1976D2 0%, #01579B 100%)",
  },
}));

const Wrapper = styled.div`
  background: #fff;
  height: 100%;
  border-radius: 20px 20px 0 0;
  margin-top: 20px;
`;

const CaptureImage = styled(Button)`
  border-radius: 5px;
  color: #fff;
  background: #ee1c25;
`;

const Preview = styled.video`
  width: 100%;
  height: 300px;
`;

export default function VideoPreview({ userData }) {
  const { alert } = useAlert();
  const axios = useAxios();
  const classes = useStyles();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  function captureAgain() {
    navigate(pages.captureImagePage.route, {
      state: { userData: userData?.state },
    });
  }

  function proceedToNext() {
    setLoading(true);
    var bucketName = "dc-chat-media";
    var bucketRegion = "ap-south-1";
    var identityPoolID = "ap-south-1:6b1fbc12-e7e7-4efd-a152-1fbce3687103";

    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: identityPoolID,
      }),
    });

    var filePath =
      "sample_data_files/" + parseInt(new Date().getTime()) + ".webm";

    var upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: bucketName,
        Key: filePath,
        Body: userData.state.blob,
        ContentType: "video/webm",
        ACL: "public-read",
      },
    });

    var promise = upload.promise();

    promise.then(
      function (data) {
        let userID = localStorage.getItem("user-id");
        // axios({
        //   url: "/generate-keys",
        //   method: "POST",
        //   data: {
        //     user_id: userID,
        //     url: data.Location,
        //   },
        // }).then((response) => {
        //   if (response.status) {
        //     Cookies.set("image-data", response.data.encrypted_data);
        //     // Cookies.set("image-data", response.data.encrypted_data, {
        //     //   domain: "digiyatra-uat.metawing.ai",
        //     //   secure: true,
        //     // });
        //   }
        // });
        navigate(pages.userDetailsPage.route, {
          state: { userData: userData.state.userData },
        });
      },
      function (err) {
        return alert("There was an error uploading your photo: ", err.message);
      }
    );
  }

  var blob = URL.createObjectURL(userData.state.blob);
  return (
    <Wrapper>
      <Grid container spacing={4} p="10px">
        <Grid item xs={12}>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: "18px",
              color: "#333",
            }}
          >
            Step 3 Of 4
          </Typography>

          <Typography
            style={{
              fontWeight: "400",
              fontSize: "16px",
              color: "#b2b2b2",
            }}
          >
            Check the preview of video before proceeding further.
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Preview controls src={blob} />
        </Grid>

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <LoadingButton
            fullWidth
            className={classes.buttonStyle}
            variant="contained"
            onClick={proceedToNext}
            loading={loading}
          >
            Proceed Next
          </LoadingButton>
        </Grid>

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <CaptureImage
            fullWidth
            className={classes.buttonStyle}
            variant="contained"
            onClick={captureAgain}
          >
            Capture Again
          </CaptureImage>
        </Grid>
      </Grid>
    </Wrapper>
  );
}
