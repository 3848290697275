import { sha256 } from "js-sha256";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { Sync } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { IoIosArrowDropright } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Grid,
  IconButton,
  CircularProgress,
  InputAdornment,
} from "@mui/material";

import { useAxiosV2, useComponent } from "../../../hooks";
import pages from "../../../constants/pages";
import TextField from "../../../components/PrimaryTextField";
import { setAadharData } from "../../../redux/slices/documentSlice";

const useStyles = makeStyles(() => ({
  buttonStyle: {
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    color: "#fff",
    padding: "5px 35px",
    fontSize: "16px",
    background: "linear-gradient(180deg, #1976D2 0%, #01579B 100%)",
  },
}));

export default function AdharForm({
  otpStatus,
  setOtpStatus,
  setFirstOtp,
  sendFirstOtp,
  captcha,
  setCaptcha,
  token,
  details,
  setDetails,
}) {
  const data = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { alert } = useComponent();

  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");

  const axios = useAxiosV2({ baseURL: "aadharUrl" });

  const userDetails = useSelector((state) => state.userIdentityDetails);

  function getCaptcha() {
    axios({
      url: "/getNewCaptcha",
      method: "POST",
      data: {
        headers: {
          client_code: "testing",
          sub_client_code: "testing",
          channel_code: "web",
          channel_version: "1",
          stan: "234fdfgb",
          client_ip: "",
          transmission_datetime: "1624812328201",
          operation_mode: "SELF",
          run_mode: "",
          actor_type: "DEFAULT",
          user_handle_type: "EMAIL",
          user_handle_value: "abc@gmail.com",
          function_code: "DEFAULT",
          function_sub_code: "DEFAULT",
        },
        request: {
          uuid: captcha.uuid,
        },
      },
      authToken: token,
    }).then((response) => {});
  }

  function handleCaptcha(e) {
    setCaptchaValue(e.target.value);
  }

  useEffect(() => {
    if (data?.state?.userData || data.state !== null) {
      dispatch(setAadharData(data?.state?.userData));
    }
  }, [dispatch, data?.state?.userData, data]);

  function handleFirstOtp() {
    setLoading(true);
    axios({
      url: "/enter/aadhar/",
      method: "POST",
      authToken: token,
      data: {
        headers: {
          client_code: "META5488",
          sub_client_code: "demo1",
          channel_code: "ANDROID_SDK",
          channel_version: "1",
          stan: "23asasd+",
          client_ip: "",
          transmission_datetime: "1624812328201",
          operation_mode: "SELF",
          run_mode: "DEFAULT",
          actor_type: "DEFAULT",
          user_handle_type: "EMAIL",
          user_handle_value: "santh@gmail.com",
          location: "",
          function_code: "DEFAULT",
          function_sub_code: "DEFAULT",
        },
        request: {
          uuid: captcha.uuid,
          aadhaar: details.adhar_number,
          captcha: captchaValue,
          verification_type: "OTP",
          consent: "YES",
        },
      },
    }).then((response) => {
      if (response.response_code === 101) {
        setLoading(false);
        if (response.data.response_status.code === "000") {
          alert.success("OTP sent on the registered number.");
          setFirstOtp(true);
        } else if (response.data.response_status.code === "470165") {
          alert.error("Please enter valid captcha.");
          setFirstOtp(false);
        }
      } else {
        alert.error("Error in sending OTP.");
      }
    });
  }

  function handleChange(event) {
    setOtp(event.target.value);
    if (event.target.value.length === 6) {
      axios({
        url: "/enter/otp/",
        method: "POST",
        authToken: token,
        data: {
          headers: {
            client_code: "META5488",
            sub_client_code: "demo1",
            channel_code: "ANDROID_SDK",
            channel_version: "1",
            stan: "651561d145h",
            client_ip: "",
            transmission_datetime: "1624812328201",
            operation_mode: "SELF",
            run_mode: "DEFAULT",
            actor_type: "DEFAULT",
            user_handle_type: "EMAIL",
            user_handle_value: "santh@gmail.com",
            location: "",
            function_code: "DEFAULT",
            function_sub_code: "DEFAULT",
          },
          request: {
            uuid: captcha.uuid,
            otp: event.target.value,
            share_code: "1234",
          },
        },
      }).then((response) => {
        if (response.response_code === 101) {
          if (response.data?.response_status?.code === "470021") {
            alert.error("Please enter the correct OTP.");
          } else if (response.data?.response_status?.code === "000") {
            axios({
              url: "/fetch/kyc/",
              method: "POST",
              authToken: token,
              data: {
                headers: {
                  client_code: "META5488",
                  sub_client_code: "demo1",
                  channel_code: "ANDROID_SDK",
                  channel_version: "1",
                  stan: "65156a6+/*",
                  client_ip: "",
                  transmission_datetime: "1624812328201",
                  operation_mode: "SELF",
                  run_mode: "DEFAULT",
                  actor_type: "DEFAULT",
                  user_handle_type: "EMAIL",
                  user_handle_value: "santh@gmail.com",
                  location: "",
                  function_code: "DEFAULT",
                  function_sub_code: "DEFAULT",
                },
                request: {
                  api_key: "h73j93ja092",
                  uuid: captcha.uuid,
                  hash: sha256(
                    `META5488|${captcha.uuid}|KijfgGFw3eMeta|PassMeta`
                  ),
                },
              },
            }).then((res) => {
              if (res.response_code === 101) {
                if (res.data.response_status.code === "000") {
                  let payload = {
                    identityType: "aadhaar",
                    aadharNo: res.data.response_data.document_id,
                    name: res.data.response_data.name,
                    gender: res.data.response_data.gender,
                    dob: res.data.response_data.dob,
                    address: res.data.response_data.address,
                  };
                  dispatch(setAadharData(payload));
                  alert.success("OTP verified successfully.");
                  setOtpStatus(true);
                } else if (res.data?.response_status?.code === "470021") {
                }
              }
            });
          }
        }
      });
    }
  }

  function handleIdentity() {
    axios({
      url: "/send-aadhar-data",
      method: "POST",
      authToken: token,
      data: {
        "cp-id": localStorage.getItem("cp_id"),
        "agent-id": localStorage.getItem("agent_id"),
        ...userDetails,
      },
    }).then((response) => {
      console.log(response);
    });

    // navigate(pages.captureImagePage.route, {
    //   state: { userData: userDetails },
    // });
  }

  function handleAdharCard(e) {
    setDetails((val) => ({
      ...val,
      adhar_number: e.target.value,
    }));
  }

  return (
    <React.Fragment>
      {!otpStatus && (
        <React.Fragment>
          <Grid item xs={12}>
            <TextField
              label="Aadhaar Card"
              value={details.adhar_number}
              onChange={handleAdharCard}
            />
          </Grid>

          {!sendFirstOtp && (
            <React.Fragment>
              <Grid
                item
                xs={5}
                style={{
                  alignSelf: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {captcha.loading ? (
                  <CircularProgress />
                ) : (
                  <img
                    style={{ height: "inherit" }}
                    src={"data:image/png;base64," + captcha.captcha}
                    alt="error"
                  ></img>
                )}
              </Grid>

              <Grid item xs={2} alignSelf="center" textAlign="center">
                <IconButton style={{ padding: "0" }} onClick={getCaptcha}>
                  <Sync />
                </IconButton>
              </Grid>

              <Grid item xs={5}>
                <TextField
                  label="Captcha"
                  name="captchaValue"
                  value={captchaValue}
                  onChange={handleCaptcha}
                />
              </Grid>
            </React.Fragment>
          )}

          {!sendFirstOtp && (
            <Grid item xs={12} alignSelf="center">
              <LoadingButton
                variant="outlined"
                component="span"
                fullWidth
                disabled={!captchaValue}
                onClick={handleFirstOtp}
                loading={loading}
                style={{
                  padding: "5px 35px",
                  fontSize: "16px",
                }}
              >
                Send OTP
              </LoadingButton>
            </Grid>
          )}
        </React.Fragment>
      )}

      {!otpStatus && sendFirstOtp && (
        <React.Fragment>
          <Grid item xs={12}>
            <TextField
              label="OTP"
              value={otp}
              disabled={otp.length === 6}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={"end"}>
                    {otp.length === 6 && <CircularProgress />}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} alignSelf="center">
            <LoadingButton
              variant="outlined"
              component="span"
              fullWidth
              style={{
                padding: "5px 35px",
                fontSize: "16px",
              }}
            >
              Resend OTP
            </LoadingButton>
          </Grid>
        </React.Fragment>
      )}

      {otpStatus && (
        <React.Fragment>
          <Grid item xs={12}>
            <TextField
              label="Aadhaar Card Number"
              value={userDetails.aadharNo}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField label="Name" value={userDetails.name} />
          </Grid>

          <Grid item xs={12}>
            <TextField label="Gender" value={userDetails.gender} />
          </Grid>

          <Grid item xs={12}>
            <TextField label="DOB" value={userDetails.dob} />
          </Grid>

          <Grid item xs={12}>
            <TextField label="Address" value={userDetails.address} />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              className={classes.buttonStyle}
              variant="contained"
              component="span"
              fullWidth
              loading={loading}
              onClick={handleIdentity}
              endIcon={<IoIosArrowDropright />}
              style={{
                padding: "5px 35px",
                fontSize: "16px",
              }}
            >
              Confirm Details
            </LoadingButton>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
