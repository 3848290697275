import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

import DocumentSelection from "./DocumentSelection";

export default function Document() {
  const location = useLocation();
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const agent_id = urlParams.get("agent-id");
    const cp_id = urlParams.get("cp-id");
    localStorage.setItem("agent_id", agent_id);
    localStorage.setItem("cp_id", cp_id);
  }, [location.search]);

  return (
    <React.Fragment>
      <Grid container spacing={2} p="10px">
        <Grid item xs={12}>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: "26px",
              color: "#fff",
              lineHeight: "30px",
            }}
          >
            Upload Document
          </Typography>
        </Grid>
      </Grid>
      <DocumentSelection />
    </React.Fragment>
  );
}
