import React, { useRef } from "react";
import { Routes, Route } from "react-router-dom";
import DigiYatra from "../layout/DigiYatraLayout";
// import Layout from "../layout/Layout";
import digiyatraRoute from "./router components/digiyatraRoute";

//Components

export default function Router() {
  const allRoutes = useRef([
    {
      ID: "digiyatra",
      layout: DigiYatra,
      children: allChildrenRoutes(digiyatraRoute),
    },
  ]);
  return (
    <Routes>
      {allRoutes.current.map(
        ({ ID: layoutID, path, layout: Layout, children }) => (
          <Route key={layoutID} path={path} element={<Layout />}>
            {children.map(
              ({ ID: pageID, index, path, element: Element, disableAuth }) =>
                pageID && (
                  <Route
                    key={pageID}
                    index={!!index}
                    path={path}
                    element={
                      <Element pageID={pageID} disableAuth={!!disableAuth} />
                    }
                  />
                )
            )}
          </Route>
        )
      )}
    </Routes>
  );
}
function allChildrenRoutes(parentObject) {
  const childrenArray = [];

  for (var key in parentObject) {
    childrenArray.push(parentObject[key]);
  }

  return childrenArray;
}
